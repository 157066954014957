<template>
  <transition name="slide-bottom">
    <div @click.self="close" v-if="value" class="ui-alert__backdrop">
      <div class="ui-alert">
        <img v-if="showClose" @click="close" class="ui-alert__close" src="@/assets/svg/close/white.svg" alt="" />
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "UiAlert",

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    close() {
      this.$emit('input', false)
    }
  },
  // watch: {
  //   value() {
  //     if (this.value) {
  //       document.querySelector('.header').style.position = 'static' || ''
  //     }
  //     else {
  //       document.querySelector('.header').style.position = 'fixed' || ''
  //     }
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.ui-alert {
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  background: #F5F5F5;
  box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
  border-radius: 10px;
  min-width: 366px;
  position: relative;

  &__backdrop {
     top: -20px;
     left: 0;
     bottom: -20px;
     right: 0;
     position: fixed;
     display: flex;
     justify-content: center;
     align-items: center;
     background: rgba(153, 153, 153, 0.8);
     backdrop-filter: blur(2.5px);
     transition: .3s;
     z-index: 10000;
   }

  ::v-deep &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  ::v-deep &__title {
     font-weight: 400;
     font-size: 20px;
     line-height: 24px;
     text-align: center;
     max-width: 295px;
  }

  ::v-deep &__text {
     font-weight: 400;
     max-width: 326px;
     font-size: 16px;
     text-align: center;
  }

  ::v-deep &__action {
     width: 100%;
     display: flex;
     flex-direction: column;
     gap: 20px;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: -20px;
    top: -20px;
  }
}

</style>
