<template>
  <button
      @click="$emit('click')"
      :class="['button', color, { icon: icon }, { disabled: disabled }]"
      :disabled="disabled"
  >
    <slot></slot>
  </button>

</template>

<script>
export default {
  name: "UiButton",

  props: {
    color: {
      type: String,
      default: 'success',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style lang="scss" scoped>
.button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 13px 0 14px;
  border: none;
  outline: none;
  border-radius: 200px;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  color: #FFFFFF;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none ;

  &.success {
    background: #02AD21;
    box-shadow: 2px 2px 10px 0px rgba(153, 153, 153, 0.20);

    &.disabled {
      filter: none;
      background: #BFBDBD;
      cursor: not-allowed;
    }
  }

  &.outline {
    background: transparent;
    color: #343432;

    &:hover {
      text-decoration: underline;
      color: #5DC579;
    }

    &.disabled {
      color: #9A9A9A;
      cursor: not-allowed;
      text-decoration: none;
    }
  }
  &.white {
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
    filter: none;
    border-radius: 200px;
    color: #343432;

    &:hover {
      filter: none;
      background: #343432;
      color: #FFFFFF;
    }

    &.disabled {
      filter: none;
      background: #BFBDBD;
      cursor: not-allowed;
    }
  }

  &.error {
    background: #E21F1F;
    box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);

    &.disabled {
      background: #BFBDBD;
      cursor: not-allowed;
    }
  }

  &.white {
    background: #FFFFFF;
  }

  &.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
</style>