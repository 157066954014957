export const getDate = (date) => {
    function formatDate(date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    return formatDate(date)
}

export const getFullDate = (date) => {
    // const months = ['январь', 'февраль', 'мартa', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь']
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']

    let d = new Date(date),

    month = (d.getMonth()),
    day = d.getDate(),
    year = d.getFullYear(),
    hour = '' + d.getHours(),
    minute = '' + d.getMinutes()

    if(day < 10) {
        day = '0' + day;
    }

    if (hour.length < 2)
        hour = '0' + hour;

    if (minute.length < 2)
        minute = '0' + minute;

    return day + '.' + months[month] + '.' + year + ', ' + hour + ':' + minute
}

export const capitalize = (word) => {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
}

export const getStatus = (val) => {
    let status = {}

    switch (val) {
        case 'Accepted':
            status = {
                text: 'open-1',
                color: '#CE2121'
            }
            break;
        case 'Closed':
            status = {
                text: 'closed-1',
                color: '#39CE21',
            }
            break;
        case 'Draft':
            status = {
                text: 'draft',
                color: '#2132CE',
            }
            break;
        case 'Moderation':
            status = {
                text: 'under-moderation',
                color: '#FD9F32',
            }
            break;
        case 'Rejected':
            status = {
                text: 'rejected-1',
                color: '#BFBDBD',
            }
            break;
        default:
            break
    }
    return status
}

export const getEsgStatus = (val) => {
    let status = {}

    switch (val) {
        case 'Accepted':
            status = {
                text: 'accepted',
                color: '#CE2121'
            }
            break;
        case 'Closed':
            status = {
                text: 'closed-1',
                color: '#39CE21',
            }
            break;
        case 'Draft':
            status = {
                text: 'draft',
                color: '#2132CE',
            }
            break;
        case 'Moderation':
            status = {
                text: 'under-moderation',
                color: '#FD9F32',
            }
            break;
        case 'Rejected':
            status = {
                text: 'rejected-1',
                color: '#BFBDBD',
            }
            break;
        default:
            break
    }
    return status
}

export const getTechSupportStatus = (val) => {
    let status = {}

    switch (val) {
        case 'New':
            status = {
                text: 'new',
                color: '#FD9F32'
            }
            break;
        case 'InWork':
            status = {
                text: 'open',
                color: '#CE2121'
            }
            break;
        case 'Resolved':
            status = {
                text: 'closed-1',
                color: '#39CE21',
            }
            break;
        default:
            break
    }
    return status
}

export const removeFormat = (formatPhone) => {
    let phone = formatPhone
    phone = phone.replace(/\D+/g, '')
    phone = phone.substr(1, 10)

    return phone
}
export const formatPhone = (phone) => {
    const cleaned = ('' + phone).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
        return '+7 (' + match[1] + ') ' + match[2] + '-' + match[3] +'-' + match[4]
    }

    return phone
}

export const getYears = (startYear, endYear) => {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
        years.push({
            name: year,
            value: year
        });
    }
    return years.reverse();
}