<template>
  <div :class="['user-page', {'loader': getUsersLoader}]">
    <div class="user-page__nav-bar nav-bar" v-if="!getUsersLoader">
      <div class="nav-bar__title">
        <div @click="back" class="back">
          <img src="@/assets/svg/arrows/back.svg" alt="">
          <p>{{ $t('back') }}</p>
        </div>
        <router-link
            tag="p"
            :to="`/user/${usersData?.id}`"
        >{{ $t('profile') }}</router-link>
        <router-link
            style="color: #1B1A1F;font-size: 16px;"
            :to="`/user/${usersData?.id}/admin-edit`"
            v-if="getUser?.role === 'Admin'"
        >{{ $t('edit-user-profile') }}</router-link>
        <router-link
            style="color: #1B1A1F;font-size: 16px;"
            :to="`/user/${usersData?.id}/confirmation-history`"
            v-if="(getUser?.role === 'Admin' || getUser?.role === 'Moderator') && usersData.role !== 'Active'"
        >{{ $t('confirmation-history-1') }}</router-link>
      </div>
      <div class="nav-bar__delete-account" v-if="getUser?.role === 'Admin'">
        <p @click="visibleBlockAccountAlert = true" v-if="!usersData.isBlocked">{{ $t('block-participant') }}</p>
        <p @click="visibleUnBlockAccountAlert = true" v-else>{{ $t('unblock-this-participant') }}</p>
        <p  @click="visibleDeleteAccountAlert = true">{{ $t('delete-profile') }}</p>
      </div>
      <ui-menu v-model="visibleSettings" v-if="getUser?.role === 'Admin' || getUser?.role === 'Moderator'">
        <template #activator>
          <img alt="" :src="require(`@/assets/svg/icon-settings-${settingsColor}.svg`)" class="mobil-nav-bar" />
        </template>
        <template #body>
          <div class="sort">
            <router-link
                style="color: #1B1A1F;font-size: 16px;"
                :to="`/user/${usersData?.id}/admin-edit`"
                v-if="getUser?.role === 'Admin'"
                @click.native="visibleSettings = !visibleSettings"
            >{{ $t('edit-user-profile') }}</router-link>
            <router-link
                style="color: #1B1A1F;font-size: 16px;"
                :to="`/user/${usersData?.id}/confirmation-history`"
                v-if="(getUser?.role === 'Admin' || getUser?.role === 'Moderator') && usersData.role !== 'Active'"
                @click.native="visibleSettings = !visibleSettings"
            >{{ $t('confirmation-history-1') }}</router-link>
          </div>
        </template>
      </ui-menu>
      <ui-alert v-model="visibleDeleteAccountAlert">
        <div class="ui-alert__wrap">
          <div class="ui-alert__title">{{ $t('do-want-to-delete-your-account') }}</div>
          <div class="ui-alert__text">{{ $t('alert-for-deleted-account') }}</div>
        </div>
        <div class="ui-alert__action">
          <ui-button @click="deleteAccount" color="error">{{ $t('delete-account') }}</ui-button>
          <ui-button @click="visibleDeleteAccountAlert = false" color="white">{{ $t('cancel-1') }}</ui-button>
        </div>
      </ui-alert>
      <ui-alert v-model="visibleBlockAccountAlert">
        <div class="ui-alert__wrap">
          <div class="ui-alert__title">{{ $t('alert-for-block-user') }}</div>
        </div>
        <div class="ui-alert__action">
          <ui-button @click="blockAccount(true)" color="error">{{ $t('block-participant') }}</ui-button>
          <ui-button @click="visibleBlockAccountAlert = false" color="white">{{ $t('cancel-1') }}</ui-button>
        </div>
      </ui-alert>
      <ui-alert v-model="visibleUnBlockAccountAlert">
        <div class="ui-alert__wrap">
          <div class="ui-alert__title">{{ $t('alert-for-unblock-user') }}</div>
        </div>
        <div class="ui-alert__action">
          <ui-button @click="blockAccount(false)" color="error">{{ $t('unblock-this-participant') }}</ui-button>
          <ui-button @click="visibleBlockAccountAlert = false" color="white">{{ $t('cancel-1') }}</ui-button>
        </div>
      </ui-alert>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import UiMenu from "@/components/ui/UiMenu.vue";
import UiAlert from "@/components/ui/UiAlert.vue";
import UiButton from "@/components/ui/UiButton.vue";
import {getDate} from "@/utils/general";
export default {
  name: "index",
  components: {UiButton, UiAlert, UiMenu},

  data() {
    return {
      visibleLoader: true,
      visibleDeleteAccountAlert: false,
      visibleBlockAccountAlert: false,
      visibleSettings: false,
      visibleUnBlockAccountAlert: false
    }
  },
  computed: {
    ...mapGetters(['usersData', 'getUser', 'getUsersLoader']),
    settingsColor () {
      return this.visibleSettings ? 'red' : 'black'
    }
  },
  methods: {
    ...mapActions(['userId', 'editUser']),
    ...mapMutations(['setUsersLoader']),

    deleteAccount() {
      this.reqDeleteAccount()
          .then(() => {
            this.visibleDeleteAccountAlert = false
          })
      console.log(111);
    },

    blockAccount(isBlocked) {
      this.setUsersLoader(true)
      this.editUser({
        acceptedOrdersCount: this.usersData.acceptedOrdersCount,
        avatar: this.usersData.avatar,
        avatarSrc: this.usersData.avatarSrc,
        birthDate: this.usersData.birthDate,
        city: this.usersData.city,
        companyName: this.usersData.companyName,
        deletedAt: this.usersData.deletedAt,
        district: this.usersData.district,
        districtId: this.usersData.districtId,
        documentFile: this.usersData.documentFile,
        documentIssueDate: getDate(this.usersData.documentIssueDate),
        documentNumber: this.usersData.documentNumber,
        documentsSrc: this.usersData.documentsSrc,
        email: this.usersData.email,
        firstName: this.usersData.firstName,
        id: this.usersData.id,
        isBlocked: isBlocked,
        lastName: this.usersData.lastName,
        login: this.usersData.login,
        middleName: this.usersData.middleName,
        mustChangePassword: this.usersData.mustChangePassword,
        phone: this.usersData.phone,
        region: this.usersData.region,
        regionId: this.usersData.regionId,
        role: this.usersData.role,
        roleRejectComment: this.usersData.roleRejectComment,
        score: this.usersData.score,
        starRating: this.usersData.starRating,
        status: this.usersData.status,
        taxNumber: this.usersData.taxNumber,
        totalOrdersCount: this.usersData.totalOrdersCount,
      }).then(() => {
        this.visibleBlockAccountAlert = false
        this.visibleUnBlockAccountAlert = false
        this.userId(this.$route.params.id).then(() => {
          this.setUsersLoader(false)
        })
      })
    },

    back() {
      history.back()
    }
  },
  created() {
    this.userId(this.$route.params.id).then(() => {
      this.visibleLoader = false
    })
  }
}
</script>

<style lang="scss" scoped>
.user-page {
  background: #F5F5F5;
  padding: 150px 50px 50px 50px;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 96px auto;
  align-items: flex-start;
  gap: 357px;

  &.loader {
    display: flex;
  }

  @media (max-width: 1200px) {
    gap: 200px;
  }

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    padding: 104px 20px 92px 20px;
    gap: 25px;
  }

  .nav-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 300px;

    @media (max-width: 1000px) {
      flex-direction: row;
      width: 100%;
    }

    &__back {
      width: 24px;
      height: 24px;
    }

    &__title {
      width: fit-content;
      font-weight: 400;
      font-size: 20px;
      line-height: 22px;
      color: #343432;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .back {
        display: flex;
        gap: 8px;

        @media (max-width: 1000px) {
          display: none;
        }

        p {
          font-weight: 400;
          font-size: 20px;
          line-height: 22px;
          color: #1B1A1F;
        }

        img {
          width: 24px;
          height: 24px;
        }
      }

      a {
        @media (max-width: 1000px) {
          display: none;
        }
      }
    }

    &__delete-account {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 230px;

      @media (max-width: 1200px) {
        max-width: 230px;
        width: 100%;
      }

      @media (max-width: 992px) {
        display: none;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        cursor: pointer;
        color: #9A9A9A;
      }
    }
  }
}
::v-deep(.ui-menu) {
  display: none;

  @media (max-width: 1000px) {
    display: inline-block;
  }
}
</style>
